
import { defineComponent } from 'vue'
import { randomFileServer } from '@/services/API'
import { emitter } from '@/main'
import { EmittEvent } from '@/dto/enums/EmittEvent'
import { logProfileClick } from '@/services/EventLogger'

export default defineComponent({
  setup() {
    return { }
  },

  props: {
    avatarURL: {
      type: String,
      default: ''
    },
    userLevel: {
      type: Number,
      default: 0
    }
  },

  computed: {
    fullAvatarURL(): string {
      const fs = randomFileServer()
      if (fs && this.avatarURL) {
        const fullPath = fs.concat(this.avatarURL)
        return fullPath
      } else {
        // ... RETURN DEFAULT IMAGE
      }
      return ''
    },
    userLevelBorder() {
      // border: solid $color_surface_overlay

      // normal
      let borderColor = '#FFFFFF'

      switch (this.userLevel) {
        case 1:
          // moderator
          borderColor = '#FAB004'
          break
        case 2:
          // admin
          borderColor = '#00D970'
          break
        case 3:
          // banned
          borderColor = '#FFFFFF'
          break
        case 4:
          // premium
          borderColor = '#FAB004'
          break
        default:
          break
      }

      return {
        border: 'solid 2px ' + borderColor
      }
    }
  },

  methods: {
    clickAvatar() {
      logProfileClick()
      emitter.emit(EmittEvent.downloadAppAlert)
    }
  }
})
