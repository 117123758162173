
import { defineComponent } from 'vue'
import { ItemComment } from '@/dto/ItemComment'
import { emitter } from '@/main'
import { EmittEvent } from '@/dto/enums/EmittEvent'
import UserAvatarComponent from '@/views/components/UserAvatarComponent.vue'
import { logProfileClick } from '@/services/EventLogger'
import { randomFileServer } from '@/services/API'

export default defineComponent({
  components: {
    UserAvatarComponent
  },

  props: {
    comment: {
      type: ItemComment,
      required: true
    }
  },

  setup() {
    return { }
  },

  computed: {
    creationString(): String {
      return this.comment.created.toLocaleString()
    },

    gifLink(): String | null {
      if (!this.comment.mediaUrl) {
        return null
      }

      if (!this.comment.mediaUrl.startsWith('http')) {
        // https://file1.live.mega.lol/pool/comment/gif/1777611.gif
        return randomFileServer() + this.comment.mediaUrl
      }

      return this.comment.mediaUrl
    }
  },

  methods: {
    clickProfile() {
      logProfileClick()
      emitter.emit(EmittEvent.downloadAppAlert)
    }
  }
})
