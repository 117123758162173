import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4af07dc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "mediaWrapper" }
const _hoisted_2 = {
  key: 0,
  controlsList: "nodownload",
  oncontextmenu: "return false;",
  autoplay: "",
  muted: "",
  loop: "",
  controls: ""
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.mediaType === _ctx.ItemType.Video)
        ? (_openBlock(), _createElementBlock("video", _hoisted_2, [
            _createElementVNode("source", {
              src: _ctx.fileURL,
              type: "video/mp4"
            }, null, 8, _hoisted_3)
          ]))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.fileURL
          }, null, 8, _hoisted_4))
    ])
  ]))
}