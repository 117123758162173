<template>
  <div class="container" id="commentArea">
    <div class="row">
      <div v-for="comm in comments.slice(0, 1)" :key="comm.id">
        <comment-component :comment="comm" />
      </div>
    </div>

    <div class="row">
      <div>
        <button
          type="button"
          class="btn btn-info"
          id="showAllCommentsButton"
          v-on:click="showComments"
        >
          <span v-if="commentCount > 1">{{
            t("showComments", commentCount)
          }}</span>
          <span v-else>{{ t("writeComment") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ItemComment } from '@/dto/ItemComment'
import CommentComponent from '@/views/components/item/comments/CommentComponent.vue'
import { emitter } from '@/main'
import { EmittEvent } from '@/dto/enums/EmittEvent'
import { logCommentClick } from '@/services/EventLogger'

import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    commentCount: {
      type: Number,
      default: 0
    },
    comments: {
      type: Array as PropType<Array<ItemComment>>,
      default: [] as PropType<Array<ItemComment>>
    }
  },

  components: {
    CommentComponent
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })

    return { t }
  },

  methods: {
    showComments() {
      logCommentClick()
      emitter.emit(EmittEvent.downloadAppAlert)
    }
  },

  emits: ['downloadAlert']
})
</script>

<style lang="sass" scoped>
#commentArea
  padding: 0

#showAllCommentsButton
  margin-top: 12px
  width: 100%
  padding-top: 12px
  padding-bottom: 12px
  border-radius: 12px
  overflow: hidden
  font-weight: 500
</style>

<i18n>
{
  "de": {
    "showComments": "Alle Kommentare anzeigen | Alle {n} Kommentare anzeigen",
    "commentCount": "{n} Kommentare",
    "writeComment": "Schreibe ein Kommentar",
  },

  "en": {
    "showComments": "Show all comments |Show all {n} comments",
    "commentCount": "{n} Comments",
    "writeComment": "Write a comment"
  }
}
</i18n>
