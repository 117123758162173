
import { defineComponent, ref } from 'vue'
import { logShare } from '@/services/EventLogger'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const linkCopied = ref(false)

    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    return { linkCopied, t }
  },

  props: {
    isHidden: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      required: true
    }
  },

  emits: [
    'share:closeAlert'
  ],

  methods: {
    clickedSocialShare() {
      logShare()
    },
    copyLink() {
      var textArea = document.createElement('textarea')
      textArea.value = this.link
      textArea.style.opacity = '0'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        if (document.execCommand('copy')) {
          this.linkCopied = true
        }
      } catch (err) {
        console.error('Unable to copy value , error : ' + err.message)
      }

      document.body.removeChild(textArea)
      logShare()
    },

    closeAlert() {
      this.$emit('share:closeAlert')
      this.linkCopied = false
    }
  },

  computed: {
    facebookLink(): string {
      // Can't include custom text, only link :(
      return 'https://www.facebook.com/sharer/sharer.php?u=' + this.link + '&quote=' + this.socialShareText
    },
    twitterLink(): string {
      return 'https://twitter.com/share?url=' + this.socialShareText + '' + this.link
    },
    emailLink(): string {
      return 'mailto:?body=' + this.socialShareText + '' + this.link
    },
    socialShareText(): string {
      return this.t('shareText') + ' '
    }
  }
})
