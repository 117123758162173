import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9332a16a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "container",
  id: "commentArea"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_comment_component = _resolveComponent("comment-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comments.slice(0, 1), (comm) => {
        return (_openBlock(), _createElementBlock("div", {
          key: comm.id
        }, [
          _createVNode(_component_comment_component, { comment: comm }, null, 8, ["comment"])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-info",
          id: "showAllCommentsButton",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showComments && _ctx.showComments(...args)))
        }, [
          (_ctx.commentCount > 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.t("showComments", _ctx.commentCount)), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.t("writeComment")), 1))
        ])
      ])
    ])
  ]))
}