
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { Tag } from '@/dto/Tag'
import { emitter } from '@/main'
import { EmittEvent } from '@/dto/enums/EmittEvent'
import { logTagClick, logVoteClick } from '@/services/EventLogger'

export default defineComponent({
  props: {
    title: {
      type: String
    },
    downloads: {
      type: Number,
      default: 0
    },
    shares: {
      type: Number,
      default: 0
    },
    views: {
      type: Number,
      default: 0
    },
    upvotes: {
      type: Number,
      default: 0
    },
    downvotes: {
      type: Number,
      default: 0
    },
    tags: {
      type: Array as PropType<Array<Tag>>,
      default: [] as PropType<Array<Tag>>
    }
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })

    return { t }
  },

  methods: {
    showTag() {
      logTagClick()
      this.showAppDownloadAlert()
    },
    vote() {
      logVoteClick()
      this.showAppDownloadAlert()
    },
    showAppDownloadAlert() {
      emitter.emit(EmittEvent.downloadAppAlert)
    }
  }
})
