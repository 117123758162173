
import { defineComponent, PropType } from 'vue'
import { ItemComment } from '@/dto/ItemComment'
import CommentComponent from '@/views/components/item/comments/CommentComponent.vue'
import { emitter } from '@/main'
import { EmittEvent } from '@/dto/enums/EmittEvent'
import { logCommentClick } from '@/services/EventLogger'

import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    commentCount: {
      type: Number,
      default: 0
    },
    comments: {
      type: Array as PropType<Array<ItemComment>>,
      default: [] as PropType<Array<ItemComment>>
    }
  },

  components: {
    CommentComponent
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })

    return { t }
  },

  methods: {
    showComments() {
      logCommentClick()
      emitter.emit(EmittEvent.downloadAppAlert)
    }
  },

  emits: ['downloadAlert']
})
