<template>
  <div v-if="!isHidden" id="alertBackground">
    <footer id="alertWrapper">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-11 col-md-8 col-lg-6 col-xl-5 col-xxl-4" style="padding: 0">
            <div id="alert">
              <svg id="closeButton" @click="closeAlert">
                <use href="@/assets/icons/close.svg#shape"></use>
              </svg>

              <img src="@/assets/images/invite.gif" id="alertImage" />
              <p id="alertTitle">{{ t('shareItem') }}</p>

              <div id="alertButtonWrapper">
                <a class="alertButton" :href="facebookLink" target="_blank" rel="noopener" @click="clickedSocialShare" id="openButton">{{ t('Facebook') }}</a>
                <a class="alertButton" :href="twitterLink" target="_blank" rel="noopener" @click="clickedSocialShare" id="openButton">{{ t('Twitter') }}</a>
                <a class="alertButton" :href="emailLink" target="_blank" rel="noopener" @click="clickedSocialShare" id="openButton">{{ t('Email') }}</a>
                <a class="alertButton" id="openButton" @click="copyLink">
                  <span v-if="!linkCopied">{{ t('copyLink') }}</span>
                  <span v-if="linkCopied" id="linkCopied">{{ t('copiedLink') }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { logShare } from '@/services/EventLogger'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const linkCopied = ref(false)

    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    return { linkCopied, t }
  },

  props: {
    isHidden: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      required: true
    }
  },

  emits: [
    'share:closeAlert'
  ],

  methods: {
    clickedSocialShare() {
      logShare()
    },
    copyLink() {
      var textArea = document.createElement('textarea')
      textArea.value = this.link
      textArea.style.opacity = '0'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        if (document.execCommand('copy')) {
          this.linkCopied = true
        }
      } catch (err) {
        console.error('Unable to copy value , error : ' + err.message)
      }

      document.body.removeChild(textArea)
      logShare()
    },

    closeAlert() {
      this.$emit('share:closeAlert')
      this.linkCopied = false
    }
  },

  computed: {
    facebookLink(): string {
      // Can't include custom text, only link :(
      return 'https://www.facebook.com/sharer/sharer.php?u=' + this.link + '&quote=' + this.socialShareText
    },
    twitterLink(): string {
      return 'https://twitter.com/share?url=' + this.socialShareText + '' + this.link
    },
    emailLink(): string {
      return 'mailto:?body=' + this.socialShareText + '' + this.link
    },
    socialShareText(): string {
      return this.t('shareText') + ' '
    }
  }
})
</script>

<style lang="sass" scoped>
#alertBackground
  background-color: #000000BB
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

#alertWrapper
  position: fixed
  left: 0
  top: 15%
  width: 100%

#alert
  position: relative
  border-radius: 12px
  padding: 0px
  width: 100%
  overflow: hidden
  background-color: $alert_background

#alertTitle
  font-size: 1.6em
  text-align: center
  font-weight: 600
  padding: 20px 20px 0px 20px
  margin-bottom: 0

#alertText
  text-align: center
  padding: 20px 20px 0px 20px
  margin-bottom: 0

#alertButtonWrapper
  padding: 0px 20px 20px 20px

.alertButton
  text-decoration: none
  width: 100%
  display: block
  text-align: center
  border-radius: 12px
  margin-top: 10px
  font-weight: 500
  background: $color_primary_overlay_light
  color: $color_on_background
  cursor: pointer

#openButton
  padding: 12px

#alertImage
  width: 100%
  display: block

#closeButton
  cursor: pointer
  height: 30px
  width: 30px
  position: absolute
  right: 12px
  top: 12px
  fill: $color_on_background

// #linkCopied
//   color: $color_secondary_variant
</style>

<i18n>
{
  "de": {
    "shareText": "Schaut euch das mal an!",
    "shareItem": "Beitrag teilen",
    "copyLink": "Link kopieren",
    "copiedLink": "Link kopiert!",
    "Facebook": "Facebook",
    "Twitter": "Twitter",
    "Email": "Email"
  },

  "en": {
    "shareText": "Take a look at THIS!",
    "shareItem": "Share this post",
    "copyLink": "Copy link",
    "copiedLink": "Link copied!",
    "Facebook": "Facebook",
    "Twitter": "Twitter",
    "Email": "Email"
  }
}
</i18n>
