import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8e4f3636"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "avatarWrapper",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickAvatar && _ctx.clickAvatar(...args)))
  }, [
    _createElementVNode("img", {
      src: _ctx.fullAvatarURL,
      id: "profileAvatar",
      style: _normalizeStyle(_ctx.userLevelBorder)
    }, null, 12, _hoisted_1)
  ]))
}