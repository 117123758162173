
import { useRoute } from 'vue-router'
import { defineComponent, reactive, ref } from 'vue'
import { fetchItem, setLanguage } from '@/services/API'
import { ItemState } from '@/dto/ItemState'
import { ItemType } from '@/dto/enums/ItemType'
import { LoadingState } from '@/dto/enums/LoadingState'

import ItemMediaComponent from '@/views/components/item/ItemMediaComponent.vue'
import ItemUserInfoComponent from '@/views/components/item/ItemUserInfoComponent.vue'
import ItemInfoComponent from '@/views/components/item/ItemInfoComponent.vue'
import ItemCommentsComponent from '@/views/components/item/comments/ItemCommentsComponent.vue'
import ShareAlert from '@/views/components/alerts/ShareAlert.vue'
import NotFoundComponent from '@/views/components/NotFoundComponent.vue'
import { createDynamicShareLink, createDynamicLink } from '@/services/DynamicLink'
import { logStartShare, logGetAppAlertClick } from '@/services/EventLogger'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import * as router from '@/router'

export const route = '/Item'

export default defineComponent({
  components: {
    ItemMediaComponent,
    ItemUserInfoComponent,
    ItemInfoComponent,
    ItemCommentsComponent,
    ShareAlert,
    NotFoundComponent
  },

  setup() {
    useHead({
      meta: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ]
    })

    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    const route = useRoute()

    const item = reactive(new ItemState())
    const itemID = route.params.id

    const hl = route.query.hl as string

    if (hl) {
      setLanguage(hl)
    }

    const shareLink = ref('')
    const shareAlertShown = ref(false)

    return { item, itemID, ItemType, shareAlertShown, LoadingState, shareLink, t }
  },

  mounted() {
    this.reloadItem()
  },

  computed: {
    mediaSize() {
      if (!this.item.item) {
        return
      }

      // The Math.floor is just a workaround because the compiler
      // has a bug and won't compile when using item directly
      const ratio = Math.floor(this.item.item.ratio * 10000)

      return {
        'aspect-ratio': '1/' + (ratio / 10000)
      }
    }
  },

  methods: {
    reloadItem: function() {
      fetchItem(+this.itemID).then(itemReponse => {
        if (itemReponse.items.length === 0) {
          console.error('ItemResponse held no items')
          this.item.loadingState = LoadingState.Failed
          return
        }

        this.item.item = itemReponse.items[0]
        this.item.comments = []

        for (var comm of itemReponse.comments) {
          if (comm.itemId === this.item.item.id) {
            this.item.comments.push(comm)
          }
        }
      }).catch(err => {
        console.error(err)

        this.item.loadingState = LoadingState.Failed
        this.item.item = undefined
        this.item.comments = []
      })
    },

    showShareAlert() {
      logStartShare()
      if (this.shareLink.length === 0) {
        this.shareLink = createDynamicLink(this.itemID as string)
        this.shareAlertShown = true

        createDynamicShareLink(this.itemID as string).then(result => {
          this.shareLink = result
        })
      } else {
        this.shareAlertShown = true
      }
    },

    hideShareAlert() {
      this.shareAlertShown = false
    },

    openApp() {
      logGetAppAlertClick()
      router.default.push({ name: router.routeNames.DOWNLOAD, params: { itemId: this.itemID } })
    }
  },

  watch: {
    '$route.params.id': {
      handler: function(newId: string | undefined) {
        if (!newId) { return }

        console.log('ITEM ID: ' + newId)
        this.shareLink = ''
        this.itemID = newId
        this.reloadItem()
      }
    },

    '$route.query.hl': {
      handler: function(newHL: string | undefined) {
        if (!newHL) { return }

        console.log('ITEM HL: ' + newHL)
        setLanguage(newHL)
        this.reloadItem()
      }
    }
  }

})
