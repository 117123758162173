import { LoadingState } from '@/dto/enums/LoadingState'
import { Item } from '@/dto/Item'
import { ItemComment } from '@/dto/ItemComment'

// State represents the Data behind a Item-Detail-View.
export class ItemState {
  loadingState: LoadingState = LoadingState.Initial
  item?: Item
  comments: ItemComment[] = []
}
