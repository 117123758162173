
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    return { t }
  },

  props: {
    showsRetryButton: {
      type: Boolean,
      default: true
    }
  },

  emits: [
    'notfound:retry'
  ],

  methods: {
    retry() {
      this.$emit('notfound:retry')
    }
  }
})
