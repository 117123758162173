import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cdf9544"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { id: "creationDateLabel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_avatar_component = _resolveComponent("user-avatar-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_user_avatar_component, {
      avatarURL: _ctx.itemUserAvatarUrl,
      userLevel: _ctx.userLevel,
      id: "userAvatar"
    }, null, 8, ["avatarURL", "userLevel"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        id: "usernameLabel",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showProfile && _ctx.showProfile(...args)))
      }, _toDisplayString(_ctx.username), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.creationString), 1)
    ])
  ]))
}