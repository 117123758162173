
import { ItemType } from '@/dto/enums/ItemType'
import { defineComponent } from 'vue'
import { randomFileServer } from '@/services/API'

export default defineComponent({
  props: {
    itemURL: {
      type: String,
      required: true
    },
    mediaType: {
      type: Number,
      required: true
    }
  },

  setup() {
    return { ItemType }
  },

  computed: {
    fileURL() {
      const fs = randomFileServer()
      if (fs) {
        const fullPath = fs.concat(this.itemURL)
        return fullPath
      }
      return null
    }
  }
})
