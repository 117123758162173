
import { defineComponent } from 'vue'
import { randomFileServer } from '@/services/API'
import { emitter } from '@/main'
import { EmittEvent } from '@/dto/enums/EmittEvent'
import UserAvatarComponent from '@/views/components/UserAvatarComponent.vue'
import { logProfileClick } from '@/services/EventLogger'

export default defineComponent({
  components: {
    UserAvatarComponent
  },

  props: {
    username: {
      type: String,
      default: 'anonym'
    },
    itemUserAvatarUrl: {
      type: String
    },
    userLevel: {
      type: Number
    },
    createdAt: {
      type: Date,
      required: true
    }
  },

  setup() {
    return { }
  },

  computed: {
    avatarURL() {
      const fs = randomFileServer()
      if (fs && this.itemUserAvatarUrl) {
        const fullPath = fs.concat(this.itemUserAvatarUrl)
        return fullPath
      } else {
        // ... RETURN DEFAULT IMAGE
      }
      return null
    },
    creationString(): String {
      return this.createdAt?.toLocaleString()
    }
  },

  methods: {
    showProfile() {
      logProfileClick()
      emitter.emit(EmittEvent.downloadAppAlert)
    }
  }
})
